<template>
    <div class="register site__centered">

        <form
          class="form"
          @submit.prevent="register"
        >
            <div class="form__wrap register__form">
                <h2 class="form__title capitalize">{{formData.role}} Account Registration!</h2>
                <p class="form__subtitle">Please register for a {{formData.role}} account.</p>
                <div class="form__row register__form-fullname">
                  <div>
                    <span class="form__row-label">First Name *</span>
                    <input
                      v-model="formData.userFirstName"
                      class="input"
                      type="text"
                      placeholder="Enter First Name"
                      :class="{'is-invalid': $v.formData.userFirstName.$dirty && !$v.formData.userFirstName.required }"
                      @input="сapitalizeFirstLetter($event.target.value, 'userFirstName')"
                    />
                    <p 
                      v-if="$v.formData.userFirstName.$dirty && !$v.formData.userFirstName.required"
                      class="error"
                    >
                      The First Name field is required
                    </p>
                  </div>
                  <div>
                    <span class="form__row-label">Last Name *</span>
                    <input
                      v-model="formData.userLastName"
                      class="input"
                      type="text"
                      placeholder="Enter Last Name"
                      :class="{'is-invalid': $v.formData.userLastName.$dirty && !$v.formData.userLastName.required }"
                      @input="сapitalizeFirstLetter($event.target.value, 'userLastName')"
                    />
                    <p 
                      v-if="$v.formData.userLastName.$dirty && !$v.formData.userLastName.required"
                      class="error"
                    >
                      The Last Name field is required
                    </p>
                  </div>
                </div>
                <label class="form__row ">
                </label>

                <label class="form__row">
                  <span class="form__row-label">Email Address *</span>
                  <input
                    v-model="formData.userEmail"
                    @change="clearserverMessage"
                    class="input"
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter Email address"
                    :class="{'is-invalid': $v.formData.userEmail.$dirty &&
                            (!$v.formData.userEmail.required || !$v.formData.userEmail.email)}"
                  />
                  <p 
                    v-if="$v.formData.userEmail.$dirty && !$v.formData.userEmail.required"
                    class="error"
                  >
                    The Email field is required
                  </p>
                  <p 
                    v-else-if="$v.formData.userEmail.$dirty && !$v.formData.userEmail.email"
                    class="error"
                  >
                    Enter a valid email
                  </p>
                </label>

                <div class="phone__row">
                  <span class="form__row-label">Phone number *</span>
                  <MazPhoneNumberInput
                    :value="formData.phone"
                    class="phone-number"
                    no-flags
                    :class="{
                      'is-invalid': 
                         ($v.formData.phone.$dirty && !$v.formData.phone.required)
                      || (enteredPhoneNumber && !enteredPhoneNumber.isValid)
                    }"
                    no-validation
                    @update="emitPhoneNamberChanges($event)"
                  />
                  <p 
                    v-if="enteredPhoneNumber && !enteredPhoneNumber.isValid"
                    class="error"
                  >
                    Valid Phone number is required
                  </p>
                  <p 
                    v-if="$v.formData.phone.$dirty && !$v.formData.phone.required"
                    class="error"
                  >
                    The Phone number field is required
                  </p>
                </div>                

                <div class="form__row">
                  <label class="form__row">
                    <span class="form__row-label">Account Type *</span>
                    <div class="input-select">
                      <select
                        v-model="formData.role"
                        class="input capitalize"
                        name="role"
                        id="role"
                      >
                        <option
                          v-for="(option, index) in roles"
                          :key="index"
                          :value="option"
                          class="capitalize"
                        >{{option}}</option>
                      </select>
                    </div>
                  </label>
                </div>

              <div class="form__row">
                <label class="form__row">
                  <span class="form__row-label">Gender *</span>
                  <div
                    class="input-select"
                  >
                    <select
                      v-model="formData.gender"
                      class="input capitalize"
                      name="role"
                      id="role"
                      :class="{'is-invalid': $v.formData.gender.$dirty && !$v.formData.gender.required }"
                    >
                      <option
                        v-for="(option, index) in genderOptions"
                        :key="index"
                        :label="option.text"
                        :value="option.value"
                        class="capitalize"
                      >{{ option.text }}</option>
                    </select>
                  </div>
                  <p
                    v-if="$v.formData.gender.$dirty && !$v.formData.gender.required"
                    class="error"
                  >
                    The Gender field is required
                  </p>
                </label>
              </div>

                <label class="form__row">
                    <span class="form__row-label">Create Password *</span>
                    <div class="form__row-pass">
                      <input
                        v-model="formData.password"
                        class="input"
                        :type="typePasword"
                        placeholder="******"
                        :class="{'is-invalid': $v.formData.password.$dirty &&
                            (!$v.formData.password.required || !$v.formData.password.minLength) }"
                      />
                      <span class="form__row-pass-show" @click="showPassword()">Show</span>
                      <p 
                        v-if="$v.formData.password.$dirty && !$v.formData.password.required"
                        class="error"
                      >
                        The Password field is required
                      </p>
                      <p 
                        v-else-if="$v.formData.password.$dirty && !$v.formData.password.minLength"
                        class="error"
                      >
                        Minimum Password length {{$v.formData.password.$params.minLength.min}} characters
                      </p>
                    </div>
                </label>

                <label class="form__row">
                  <span class="form__row-label">Confirm Password *</span>
                    <div class="form__row-pass">
                      <input
                        v-model="formData.passwordRepeat"
                        class="input"
                        :type="typePasword"
                        placeholder="******"
                        :class="{'is-invalid': $v.formData.passwordRepeat.$dirty &&
                            (!$v.formData.passwordRepeat.required || !$v.formData.passwordRepeat.minLength|| !$v.formData.passwordRepeat.sameAsPassword)}"
                      />
                      <span class="form__row-pass-show" @click="showPassword()">Show</span>
                      <p 
                        v-if="$v.formData.passwordRepeat.$dirty &&
                            (!$v.formData.passwordRepeat.required || !$v.formData.passwordRepeat.minLength|| !$v.formData.passwordRepeat.sameAsPassword)"
                        class="error"
                      >
                        Enter the same Password
                      </p>
                    </div>
                </label>

                <div class="form__row form__one-row">
                    <div class="center">
                        <input
                          v-model="formData.termsAccepted"
                          type="checkbox"
                          id="remember"
                          name="remember"
                        />
                        <label
                          for="remember"
                          :class="{'terms-error': $v.formData.termsAccepted.$dirty && !$v.formData.termsAccepted.checked}"
                        >I agree to terms & conditions</label>
                    </div>
                </div>

                <p v-if="errorMessage && $v.$invalid"
                  class="form__error"
                >
                  {{errorMessage}}
                </p>

                <p v-if="serverMessage"
                  class="form__error"
                >
                  {{serverMessage}}
                </p>

                <button class="btn btn--color-dark form__button"  :class="{ 'btn--loading': isLoading }"><span v-if="!isLoading">Register Account</span></button>
                <span class="center">Or</span>
                <router-link
                  :to="{name: 'auth-login', params: { return: returnPageRouteName, routeParams: returnPageRouteParams, role: chosenRole }}"
                  class="btn btn--color-outline-bright form__button"
                >
                  <span>Login Account</span>
                </router-link>
            </div>
        </form>
    </div>
</template>

<script>
  import store from '@/store/index'
  import useJwt from '@/auth/jwt/useJwt'
  
  import { MazPhoneNumberInput } from 'maz-ui'
  import "maz-ui/lib/css/base.css"
  import "maz-ui/lib/css/maz-phone-number-input.css"

  import { getHomeRouteForLoggedInUser } from '@/auth/utils'
  import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
  import { mapGetters } from 'vuex'
  import { prepareProgramRegistrationInfo } from '@core/mixins/prepareProgramRegistrationInfo'

  export default {
    name: 'Register',
    components: {
      MazPhoneNumberInput,
    },
    mixins: [prepareProgramRegistrationInfo],
    data() {
      return {
        status: '',
        returnPageRouteName: null,
        returnPageRouteParams: null,
        chosenRole: null,
        formData: {
          userEmail: null,
          userFirstName: null,
          userLastName: null,
          gender: null,
          phone: null,
          password: null,
          passwordRepeat: null,
          role: null,
          termsAccepted: false,
        },
        roles: null,
        isLoading: false,
        typePasword: 'password',
        errorMessage: '',
        serverMessage: '',
        enteredPhoneNumber: null,
      }
    },
    validations: {
      formData: {
        userEmail: { required, email },
        userFirstName: { required },
        userLastName: { required },
        gender: { required },
        phone: { required },
        password: {
          required,
          minLength: minLength(6),
        },
        passwordRepeat: {
          required,
          minLength: minLength(6),
          sameAsPassword: sameAs('password'),
        },
        termsAccepted: {
          checked: value => value === true
        }
      }
    },
    computed: {
      ...mapGetters({
        familyId: 'family/getFamilyId',
        authSettings: 'authSettings/getAuthSettings',
      }),
      genderOptions() {
        if (this.formData.role === 'student') {
          return [
            { text: 'Male', value: 'Boy'},
            { text: 'Female', value: 'Girl'},
          ]
        }
        return [
          { text: 'Male', value: 'Male'},
          { text: 'Female', value: 'Female'},
        ]
      }
    },
    mounted () {
      if (!this.authSettings.enable_regular_registration_for_staff && !this.authSettings.enable_regular_registration_for_parents) {
        this.$router.push({name: 'auth-login'})
      }
    },
    async created() {
      if (this.$route.params.role) {
        this.chosenRole = this.$route.params.role
      }
      if (!this.chosenRole) {
        await this.$router.push({name: 'auth-login'})
      }
      if (!this.availableRegularRegistration(this.chosenRole, this.authSettings)) {
        await this.$router.push({name: 'auth-login'})
      }
      if (this.$route.params.return){
        this.returnPageRouteName = this.$route.params.return
      }
      if (this.$route.params.routeParams){
        this.returnPageRouteParams = this.$route.params.routeParams
      }
      if (this.$route.query.email){
        this.formData.userEmail = this.$route.query.email
      }
      if (this.$route.query.family){
        store.commit('family/SET_FAMILY_ID', this.$route.query.family)
      }
      await this.prepareRolesOptions()
      await this.setRole()
    },
    methods: {
      async setRole() {
        if (this.chosenRole) {
          this.formData.role = this.chosenRole
        } else {
          this.formData.role = this.$config.enable_student_signup ? 'student' : 'parent'
        }
      },
      async prepareRolesOptions() {
        const isActive = this.$config.enable_student_signup
        const allRoles = ['parent', 'student', 'staff']

        if (!isActive) {
          allRoles.splice(allRoles.indexOf('student'), 1);
        }
        if (!this.availableRegularRegistration('staff', this.authSettings)) {
          allRoles.splice(allRoles.indexOf('staff'), 1);
        }
        if (!this.availableRegularRegistration('parent', this.authSettings)) {
          allRoles.splice(allRoles.indexOf('parent'), 1);
        }
        this.roles = allRoles
      },
      showPassword() {
        if(this.typePasword === 'password') {
            this.typePasword = 'text'
        } else {
            this.typePasword = 'password'
        }
      },
      clearserverMessage() {
        this.serverMessage = ''
      },
      сapitalizeFirstLetter(value, field){
        if (value) {
          const [firstLetter, ...rest] = value.split('')
          this.formData[field] = firstLetter.toUpperCase() + rest.join('')
        }
      },
      emitPhoneNamberChanges(e) {
        if (e.formatInternational) {
          this.enteredPhoneNumber = e
          this.formData.phone = e.formatInternational
        }
      },
      async register() {
        if (this.$v.$invalid){
          await this.$v.$touch();
          document.querySelector('.is-invalid').focus()
          this.errorMessage = 'All fields are required'
          return
        }
        if (this.enteredPhoneNumber && !this.enteredPhoneNumber.isValid){
          return
        }

        this.isLoading = true

        useJwt.register({
          first_name: this.formData.userFirstName,
          last_name: this.formData.userLastName,
          email: this.formData.userEmail,
          password: this.formData.password,
          role: this.formData.role,
          phone: this.formData.phone,
          family_id: this.familyId,
          extra_user_info: {
            gender: this.formData.gender,
          }
        })
          .then(response => {
            const {userData} = response.data

            if (!userData.is_approved) {
              this.serverMessage = 'Your account is under review, we will email you once your account has been approved.'
              return
            }

            useJwt.setToken(response.data.accessToken)
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)
            
            this.$store.commit('CHANGE_USER_STATUS', userData.id)
            this.$store.commit('SET_USER_DATA', userData)

            if (this.returnPageRouteName) {
              if (userData.role === 'parent' && this.returnPageRouteName === 'application-create') {
                this.$router.replace({ name: 'application-select-child', params: this.returnPageRouteParams})
                return
              }
              this.$router.replace({ name: this.returnPageRouteName, params: this.returnPageRouteParams})
            } else {
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            }
          })
          .catch(err => {
            if (err.response) {
              if ( err.response.status === 401 ) this.serverMessage = 'Incorrect email address and / or password'
              if ( err.response.status === 403 ) this.serverMessage = err.response.data.message
              if ( err.response.status === 422 ) {
                const serverMessage = Object.values(err.response.data.errors)
                this.serverMessage = serverMessage.flat(Infinity).join(' ')
              }
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
  }
</script>

<style scoped>
.error {
  position: absolute;
  color: red;
  font-size: 14px;
}
.terms-error{
  color: red;
}
</style>

<style lang="scss">
.phone__row{
  width: 100%;
  margin-bottom: 30px;
}
.phone-number {
  border-radius: 12px;
  .maz-input {
    height: 60px;
    background: #EDEDED;
    border: none !important;
    border-color: none !important;
  }
  .maz-input__toggle-btn {
    display: none;
  }
  &.is-invalid{
    border: 1px solid var(--error) !important;
    &::placeholder {
      color: var(--error)
    }
  }
}
</style>
